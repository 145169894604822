$env: prod;
@charset "UTF-8";

@import './color.scss';

@mixin font-mixin {
  font-family: Lato, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", Helvetica, 游ゴシック体, YuGothic, "Yu Gothic M", "游ゴシック Medium", "Yu Gothic Medium", メイリオ, Meiryo, sans-serif;
}

body {
  @include font-mixin;
  font-size: 0.8rem;
  color: $black;
}

input,
textarea {
  @include font-mixin;
}

select[disabled] {
  color: $gray153;
  background-image: url('../images/chevron_down_disabled.png') !important;
  // background-image: url("data:image/svg+xml,%3Csvg width='12' height='6' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.646447 0.146447C0.841709 -0.0488155 1.15829 -0.0488155 1.35355 0.146447L6 4.79289L10.6464 0.146447C10.8417 -0.0488155 11.1583 -0.0488155 11.3536 0.146447C11.5488 0.341709 11.5488 0.658291 11.3536 0.853553L6.35355 5.85355C6.15829 6.04882 5.84171 6.04882 5.64645 5.85355L0.646447 0.853553C0.451184 0.658291 0.451184 0.341709 0.646447 0.146447Z' fill='#DDDDDD'/%3E%3C/svg%3E") !important;
}

select {
  background-image: url("../images/chevron_down.png") !important;
  // background-image: url("data:image/svg+xml,%3Csvg width='12' height='6' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.646447 0.146447C0.841709 -0.0488155 1.15829 -0.0488155 1.35355 0.146447L6 4.79289L10.6464 0.146447C10.8417 -0.0488155 11.1583 -0.0488155 11.3536 0.146447C11.5488 0.341709 11.5488 0.658291 11.3536 0.853553L6.35355 5.85355C6.15829 6.04882 5.84171 6.04882 5.64645 5.85355L0.646447 0.853553C0.451184 0.658291 0.451184 0.341709 0.646447 0.146447Z' fill='#757575'/%3E%3C/svg%3E") !important;
  background-size: 11px 6px !important;
  background-repeat: no-repeat !important;
  background-position: calc(100% - 15px) center !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-left: 12px;
  padding-right: 33px;

  &.placeholder {
    color: $border;
  }
}

select {
  background: $white;
  border: 1px solid $border;
  border-radius: 4px;
  height: 25px;
  box-sizing: border-box;
}

label {
  display: flex;
  align-items: center;

  input {
    margin: 0 5px;
  }
}

input:not([type='checkbox'], [type='radio']):focus {
  outline: 1px solid $focus;
}

select:focus {
  outline: 1px solid $focus;
}

textarea:focus {
  outline: 1px solid $focus;
}

table {
  width: 100%;

  &,
  th,
  td {
    text-align: left;
    border-collapse: collapse;
  }

  th,
  td {
    font-weight: 400;
    border: 1px solid $border;
    padding: 5px;
  }

  thead {
    background: $gray239;
  }

  .header {
    background: $gray239;
  }

  input:not([type='checkbox'], [type='radio']) {
    border: 1px solid $border;
    border-radius: 4px;
    height: 25px;
    box-sizing: border-box;
    padding: 0px 12px;
  }

  textarea {
    border: 1px solid $border;
    border-radius: 4px;
    height: 87px;
  }

  p {
    margin: 0;
    padding: 0;
  }
}

/* ui-kit customize */

.uk-button--m {
  border-radius: 0.4rem;
  font-size: 0.8rem;
  width: 6rem;
  height: 1.8rem;
  padding: 0 0.4rem;
  line-height: 1.8rem;
  display: table-cell;
}

.uk-button--l {
  border-radius: 0.4rem;
  font-size: 0.8rem;
  width: 8rem;
  height: 1.8rem;
  padding: 0 0.4rem;
  line-height: 1.8rem;
  display: table-cell;
}

.uk-button--x {
  border-radius: 0.4rem;
  font-size: 0.8rem;
  height: 1.8rem;
  padding: 0 0.4rem;
  line-height: 1.8rem;
  display: table-cell;
}

.uk-button-cancel {
  color: #222;
  background-color: #E6EAF4;
  border: 1px solid $border;
  text-decoration-line: none;

  &:disabled,
  &:active {
    color: #222;
    background-color: #f2f6f7;
    text-decoration-line: none;
  }
}

.uk-button-cancel:active {
  color: #222;
  background-color: #F2F6F7;
  text-decoration-line: none;
}

.uk-button-cancel a {
  color: #222;
  text-decoration-line: none;
}

.uk-button-refer {
  color: $white;
  background-color: #26589E;
  border: 1px solid #26589E;

  &:disabled,
  &:active {
    color: #fff;
    background-color: #92abce;
    border: 1px solid #92abce;
    text-decoration-line: none;
  }
}

.uk-button-refer:active {
  color: $white;
  background-color: #92ABCE;
  border: 1px solid #92ABCE;
  text-decoration-line: none;
}

.uk-button-refer a {
  color: $white;
  text-decoration-line: none;
}

.uk-button-red {
  color: $white;
  background-color: #d53636;
  border: 1px solid #d53636;
}

.uk-button-red:active {
  color: $white;
  background-color: #ec5f5f;
  border: 1px solid #ec5f5f;
  text-decoration-line: none;
}

.uk-button-red a {
  color: $white;
  text-decoration-line: none;
}

.required {
  font-size: 0.5rem;
  border: 1px solid #fe7f7f;
  color: red;
  line-height: 0.625rem;
  padding: 2px 2px;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

.uk-tooltip {
  word-wrap: break-word;
  max-width: 400px;
  padding: 6px 12px;
}

.uk-background-light-red {
  background-color: $lightRed;
}

.uk-text-small {
  font-size: 0.6rem;
}

.uk-text-default {
  font-size: 0.8rem;
}

.uk-text-medium {
  font-size: 1.1rem;
}

.uk-text-white {
  color: $white;
}

.uk-color-red {
  color: $red;
}

.width-32 {
  width: 32px;
}

.width-60 {
  width: 60px;
}

.width-85 {
  width: 85px;
}

.width-110 {
  width: 110px;
}

.width-700 {
  width: 700px;
}

.padding-4 {
  padding: 4px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-40 {
  margin-top: 40px;
}

.underline {
  text-decoration-line: underline;
}

.error {
  color: $red;
  margin-left: 8px;
}

.error-form {
  border: 1px solid $red !important;
  border-radius: 4px;
}

.error-form-file {
  border: 1px solid $red !important;
  border-radius: 4px;
  padding: 5px 10px;
}

.error-form-grid {
  position: relative;
  padding-right: 5px;

  &::before {
    content: "";
    position: absolute;
    top: -3px;
    left: 15px;
    right: 0;
    bottom: -3px;
    border: 1px solid $red;
    border-radius: 4px;
    pointer-events: none;
  }
}

.error-box {
  color: $red;
  background: $lightRed;
  text-align: center;
  border: 1px solid;
  padding: 10px;
  margin-top: 5px;
}

.line-break {
  white-space: pre-wrap;
}

.cursor-pointer {
  cursor: pointer;
}

/*ヘッダー*/
header {
  background-color: $headerColor;
  height: 55px;
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  position: sticky;
  top: 0;
  z-index: 20;

  .env-label {
    color: white;
  }
}

/* フッター */
footer {
  background-color: $blue;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  z-index: 20;
}

.sub-header {
  height: 100px;
  background-image: url("../images/DRM-titleimg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/* モーダル */
.modal {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  text-align: center;
  display: flex;
  align-items: center;

  &-text {
    font-size: 1rem;
  }

  &-content {
    background-color: $gray254;
    margin: 0 auto;
    padding: 36px 0;
    border: 1px solid $gray136;
    width: 450px;
  }

  &-buttons {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
}

.form-tbl {
  min-width: 650px;

  p {
    padding: 0;
    margin: 0;
    line-height: 25px;
  }

  td:nth-of-type(1) {
    width: 15%;
    min-width: 150px;
    background: $gray239;
    padding: 10px 20px;
    position: relative;

    &.bg-white {
      background: $white;
    }
  }

  td:nth-of-type(2) {
    padding: 10px 20px;
  }

  input:not([type='checkbox'], [type='radio']) {
    padding: 0px 8px 0px 12px;
  }

  textarea {
    padding: 6px 12px;
  }

  .agree-box {
    height: 180px;
    overflow-y: auto;
    border: 1px solid $gray161;
    padding: 5px 8px;
    margin: 10px 0;
    font-size: 0.8rem;

    .title {
      font-size: 1rem;
      font-weight: bold;
    }

    .subtitle {
      font-weight: bold;
    }
  }
}

.confirm-tbl {
  td:nth-of-type(1) {
    width: 15%;
    min-width: 170px;
    background: $gray239;
    padding: 5px 20px;
    position: relative;
  }

  td:nth-of-type(2) {
    padding: 5px 10px;
  }
}

.apply-mail-sent {
  display: flex;
  margin-top: calc(50vh - 150px);

  p {
    font-size: 1rem;
    margin: 0 auto;
  }

  .red {
    color: $red;
  }
}

.step-content {
  color: $white;
  margin: auto;

  .step {
    overflow-y: hidden;
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    position: relative;
    padding: 6px 10px 6px 40px;
    margin: 0 30px 0 0;
    background: $stepInactive;
  }

  /*　先端部分 */
  li::before {
    content: "";
    position: absolute;
    top: -30px;
    right: -80px;
    border-width: 46px 40px;
    border-color: transparent transparent transparent $stepInactive;
    border-style: solid;
    z-index: 10;
  }

  /* 白いスペース部分 */
  li::after {
    content: "";
    position: absolute;
    top: -30px;
    right: -83px;
    border-width: 46px 40px;
    border-color: transparent transparent transparent $white;
    border-style: solid;
    z-index: 5;
  }

  /* 現在のステップ */
  li.is-current {
    background: $stepActive;
  }

  li.is-current::before {
    border-color: transparent transparent transparent $stepActive;
  }

  /* 最後のステップのレイアウト */
  li:last-child:after {
    display: none;
  }

  li:last-child {
    margin-right: 80px;
  }
}

.estimate-window {
  background-color: $white;
  position: fixed;
  right: 10px;
  bottom: 50px;
  width: 400px;
  font-size: 0.9rem;

  table {
    th {
      background: $gray136;
      color: $white;
      text-align: center;
      position: relative;
      font-size: 1.1rem;

      span {
        position: absolute;
        left: 5px;
        top: 4px;
      }
    }

    td:nth-of-type(1) {
      background: $gray239;
      padding: 8px 15px;
      width: 35%;
    }

    td:nth-of-type(2) {
      padding: 10px 20px;
      background-color: $white;
    }
  }
}


.messages {
  height: calc(100vh - 430px);
  overflow: auto;

  &-title {
    font-size: 0.9rem;
    background: $gray239;
    border: 1px solid $border;
    padding: 5px 20px;
    margin-top: 10px;
  }

  hr {
    border-top: 1px solid $border;
  }

  .received-message {
    width: 70%;

    &-box {
      border: 1px solid $border;
      border-radius: 15px;
      padding: 20px;
      word-wrap: break-word;

      &-title {
        font-weight: bold;
        font-size: 0.8rem;
      }
    }

    &-time {
      text-align: right;
      margin-right: 10px;
    }

    &-name {
      margin-left: 10px;
    }
  }

  .sent-message {
    width: 70%;
    margin-left: auto;

    &-box {
      border: 1px solid $border;
      border-radius: 15px;
      padding: 20px;
      word-wrap: break-word;
      background: #cfe2f3;
    }

    &-time {
      margin-left: 10px;
    }

    &-name {
      text-align: right;
      margin-right: 10px;
    }
  }
}

.message-sendto-box {
  border: 1.5px solid $gray100;
  display: flex;
  width: fit-content;
  height: 40px;
  margin-top: 24px;

  div {
    border-right: 1.5px solid $gray100;
    background: $gray239;
    text-align: left;
    padding: 10px 32px 10px 10px;
  }

  select {
    border: none;
    width: 240px;
    height: 100%;
  }

  select:focus {
    outline: 0;
  }
}
.message-input-box {
  border: 1px solid $border;
  border-radius: 15px;
  padding: 20px 20px 12px;
  margin: 15px 0;

  textarea {
    width: 100%;
    min-height: 80px;
    max-height: 180px;
    border: none;
  }

  textarea:focus,
  input:focus {
    outline: 0;
  }

  hr {
    margin: 10px 0;
  }

  .file-drop {
    border: none;
    height: 40px;
    width: 100%;
  }
}