$black: #000;
$white: #fff;
$red: #f00;
$lightRed: #ffe1e1;
$purple: #3b23b2;
$gray100: #92989a;
$gray136: #888;
$gray153: #999;
$gray161: #a1a1a1;
$gray239: #efefef;
$gray254: #fefefe;
$overlay: #00000066;
$border: #92989a;
$focus: #1e87f0;
$stepActive: #2c5185;
$stepInactive: #97b6d2;
$blue: #072c91;

$headerColor: #00CC44;
@if ($env == 'prod') {
  $headerColor: white;
} @else if ($env == 'stg') {
  $headerColor: orange;
}